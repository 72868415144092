import {create} from "zustand";

const userInfoSchema = {
    uid: '',
    name: '',
    system: '',
    permission: [],
    isLoggedIn: false
}

const userInfoStore = create((set) => ({
    info: userInfoSchema,
    update: (newInfo) => set({info: newInfo})
}));

export { userInfoStore, userInfoSchema }





import {Button, Card, Table, App} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import api from "../../../Helpers/api-helper";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {notify} from "../../../Helpers/toast-helper";
import {reloadBinSchema} from "../../../Helpers/bin-helper";

const BinSchemaList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([])

    const {modal} = App.useApp();

    useEffect(() => {
        api.post('/inventory/bin-schema/get').then((response) => {
            setData(response)
        })
    }, []);

    const dataHelper = (data) => {
        const binPart = ['one', 'two', 'three', 'four'];

        const getPart = (data) => {
            switch (data.valueType){
                case 'label':
                case 'string':
                    return data.fieldStart;
                case 'numeric':
                    return data.fieldStart.padStart(data.fieldStart.length, '0');
                case 'step':
                    return parseInt(data.fieldStart) + 1
                default:
                    return '';
            }
        }

        return data.map(entry => {
            return {
                title: binPart.map(part => {
                    const prefix = getPart(entry.schema[part].prefix);
                    const suffix = getPart(entry.schema[part].suffix);

                    return prefix + suffix;

                }).join('.'),
                key: entry._id,
                id: entry._id,
                created: dayjs(entry.created).format("DD/MM/YYYY HH:mm:ss"),
                updated: entry.updated ? dayjs(entry.updated).format("DD/MM/YYYY HH:mm:ss") : '-'
            }
        });
    }

    const deleteBinSchema = (id) => {
        modal.confirm({
            title: 'Xóa mã bin',
            icon: <ExclamationCircleOutlined />,
            content: 'Có chắc chắn muốn xóa mã Bin này?',
            okText: 'Đồng ý',
            okType: 'danger',
            cancelText: 'Bỏ qua',
            onOk() {
                return new Promise((resolve) => {
                    api.post('/inventory/bin-schema/delete', {id: id}).then(async resp => {
                        //await sleep(2000)
                        if (resp) {
                            if (resp?.deleted){
                                setData(data.filter(obj => obj._id !== resp.deleted))
                                notify('info','Xoá dữ liệu thành công');
                                reloadBinSchema()
                            }
                            resolve()
                        }
                    }).finally(() => {
                        resolve();
                    })
                }).catch(() => {}
                );
            },
            onCancel() {
            },
        });
    }

    const columns = [
        {
            title: 'Bin#',
            dataIndex: 'title',
            key: 'key',
            render: (item, row) => (<a href='' onClick={() => navigate('/inventory/bin-manager/edit/' + row.id)}>{item}</a>)
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created',
            key: 'key',
        },
        {
            title: 'Ngày sửa',
            dataIndex: 'updated',
            key: 'key',
        },
        {
            title: '',
            key: 'key',
            render: (item) => (<Button danger title='Xóa mã bin này' onClick={() => {deleteBinSchema(item.id)}}><DeleteOutlined /></Button>)
        },
    ]

    const addNewSchema = () => {
        navigate('/inventory/bin-manager/edit/new')
    }

    return(
        <>
            <Card title="Danh mục mã bin" extra={<Button type="primary" onClick={addNewSchema} icon={<PlusCircleOutlined />}>Tạo mới</Button>}>
                <Table columns={columns} dataSource={dataHelper(data)} />
            </Card>
        </>
    )
}

export default BinSchemaList
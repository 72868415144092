import {App, Layout, Button, Image} from 'antd'
import {MenuOutlined} from "@ant-design/icons";
import {LoginArea} from "../LoginArea";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {ProtectedRoute} from "../ProtectedRoute";
import PostAuth from "../PostAuth";
import NoMatch404 from "../404";
import LogoutPage from "../Logout";
import {userInfoStore} from "../../../Helpers/user-store";
import {useMemo, useState} from "react";
import MenuDrawer from "../MenuDrawer";
import BinSchemaList from "../../Admin/BinManager/bin-schema-list";
import SchemaBuilder from "../../Admin/BinManager";
import ModalScanner from "../ModalScanner";
import {reloadBinSchema} from "../../../Helpers/bin-helper";

const { Header } = Layout;
const BrandLogo = ({ brand }) => {
    const navigate = useNavigate();

    const brands = {
        TOKYOLIFE: '/TokyoLifeLogo_invert.png',
        FORMAT: '/FormatLogo_invert.png',
        FORLIFE: '/LOGO-FORLIFE-02-white.png'
    }

    const selectedBrand = brands[brand] || brands['FORLIFE'];

    return (
        <Image
            preview={false}
            height="25px"
            style={{ width: 'auto', cursor: 'pointer', paddingLeft: '10px' }}
            onClick={() => {
                navigate('/');
            }}
            src={selectedBrand}
        />
    );
}

const UtilityBox = ({brand, onClick}) => {
    return brand ? (<Button type="link" onClick={onClick} icon={<Image  preview={false} src='/barcode-scanner.png' style={{filter: 'invert(100%)'}}/>} />) : null;
}

const MainContainer = () => {
    const userInfo = userInfoStore(state => state.info)
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [scannerOpen, setScannerOpen] = useState(false);

    const handleMenuClick = () => {
        setDrawerVisible(true);
    };
    const handleCloseDrawer = () => {
        setDrawerVisible(false);
    };

    useMemo(() => {
        if (userInfo.isLoggedIn){
            reloadBinSchema();
        }
    }, [userInfo.isLoggedIn])


    return (
        <App>

            <Header style={{ height: 45, display: 'flex', alignItems: 'center', background: '#001529'}}>
                <div style={{ width: 1000, margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <Button type="text" style={{color: "white"}} icon={<MenuOutlined />} onClick={handleMenuClick} />
                        <BrandLogo brand={userInfo.system} />
                    </div>
                    <div>
                        {/*This element can be used as a search box*/}
                        <UtilityBox brand={userInfo.system} onClick={setScannerOpen}/>
                    </div>
                    <div>
                        <LoginArea />
                    </div>
                </div>
            </Header>

            <MenuDrawer drawerVisible={drawerVisible} setVisible={setDrawerVisible} handleCloseDrawer={handleCloseDrawer}/>

            <Routes>
                <Route element={<Outlet />}>
                    <Route path="/" element={<ProtectedRoute><div>This is home page</div></ProtectedRoute>} />
                    <Route path="/postAuth" element={<PostAuth />} />
                    <Route path="/logout" element={<LogoutPage />} />

                    <Route path="/inventory/bin-manager" element={<ProtectedRoute><BinSchemaList /></ProtectedRoute>} />
                    <Route path="/inventory/bin-manager/edit/:id" element={<ProtectedRoute><SchemaBuilder /></ProtectedRoute>} />
                </Route>
                <Route path="*" element={<NoMatch404 />} />
            </Routes>
            <ModalScanner open={scannerOpen} close={setScannerOpen}/>
        </App>
    );
}

export default MainContainer
import React, {useEffect, useState} from 'react';
import {Steps, Card, Form, Input, Select, Row, Col, Button, Tag} from 'antd';
import {notify} from "../../../Helpers/toast-helper";
import api from "../../../Helpers/api-helper";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined, RollbackOutlined} from "@ant-design/icons";
import {reloadBinSchema} from "../../../Helpers/bin-helper";

const { Option } = Select

const part = {
    valueType: '',
    fieldStart: '',
    fieldEnd: ''
}

const binSchema = {
    'one': {
        prefix: part,
        suffix: part
    },
    'two': {
        prefix: part,
        suffix: part
    },
    'three': {
        prefix: part,
        suffix: part
    },
    'four': {
        prefix: part,
        suffix: part
    }
}

const valueTypeOptions = ['label', 'string', 'numeric', 'step'];
const tagColors = ["magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"];

const getNumeric = (fieldStart, fieldEnd) => {
    try {
        let result = []
        let numPad = fieldStart.length;// === 1 ? 2 : fieldStart.length;

        for (let i = parseInt(fieldStart); i <= parseInt(fieldEnd); i++) {
            if (
                Math.abs(i - fieldStart) <= 1 ||
                Math.abs(i - fieldEnd) <= 1
            ) {
                result.push(String(i).padStart(numPad, '0'));
            }
        }

        return result.length > 0 ? result : false;
    } catch (e) {
        return [];
    }
}

const getString = (fieldStart, fieldEnd) => {
    const result = [];
    try {
        const startCode = fieldStart.charCodeAt(0);
        const endCode = fieldEnd.charCodeAt(0);

        for (let code = startCode; code <= endCode; code++) {
            const char = String.fromCharCode(code);

            if (
                Math.abs(code - startCode) <= 1 ||
                Math.abs(code - endCode) <= 1
            ) {
                result.push(char);
            }

        }
    } catch (e) {
        return false;
    }

    return result.length > 0 ? result : [];
}

const getStep = (fieldStart, fieldEnd, limit = 2) => {
    const result = [];
    const step = 100;
    const startNumber = parseInt(fieldStart);
    const endNumber = parseInt(fieldEnd);

    for (let i = startNumber; i <= endNumber; i += step) {
        for (let j = 1; j < 10; j++) {
            const number = i + j;

            if (number < (endNumber + step)) {
                result.push(String(number));
            }
        }
    }

    const firstSome = result.slice(0, limit);
    const lastSome = result.slice(-limit);


    return (result.length > 0) ? [...firstSome, ...lastSome] : false;
}
const previewPart = (values) => {
    const { prefix, suffix } = values;

    const generatePart = (valueType, fieldStart, fieldEnd) => {
        switch (valueType) {
            case 'label':
                return [fieldStart];
            case 'numeric':
                return getNumeric(fieldStart, fieldEnd);
            case 'string':
                return getString(fieldStart, fieldEnd);
            case 'step':
                return getStep(fieldStart, fieldEnd);
            default:
                return null;
        }
    };

    const firstPart = generatePart(prefix.valueType, prefix.fieldStart, prefix.fieldEnd);
    const secondPart = generatePart(suffix.valueType, suffix.fieldStart, suffix.fieldEnd);

    const result = firstPart ? firstPart.map(first => {
        return secondPart ? secondPart.map(second => {
            return first + second
        }) : first;
    }) : [false];

    return result.filter(Boolean).flat();
};
const generateTags = (values) => {
    if (typeof values !== 'object')
        return null;

    return values.map(item => ({
        tagName: item,
        tagColor: tagColors[Math.floor(Math.random() * tagColors.length)]
    })).map((tag, index) => {
        return(<Tag
            style={{fontFamily: 'monospace'}}
            key={index}
            closable={false}
            color={tag.tagColor}
        >{tag.tagName}
        </Tag>)
    });
}

const SchemaBuilder = () => {
    const contentStyle = {
        padding: '10px',
        color: 'black',
        backgroundColor: '#fafafa',
        borderRadius: 8,
        border: `1px dashed #f0f0f0`,
        marginTop: '16px',
        marginBottom: '16px',
    };

    const [currentStep, setCurrentStep] = useState(0);
    const [schema, setSchema] = useState(binSchema);
    const [mustStop, setMustStop] = useState(false)

    const {id: schemaId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (schemaId && (schemaId !== 'new')) {
            api.post('/inventory/bin-schema/getOne', {id: schemaId}).then((response) => {
                if (!response || (typeof response !== 'object') || (response.length === 0)) {
                    setMustStop(true);
                    notify('error', "Dữ liệu không hợp lệ")
                    return;
                }

                const {schema} = response
                if (schema && (typeof response.schema === 'object')) {
                    setSchema(schema);
                } else {
                    setMustStop(true);
                    notify('error', "Dữ liệu không hợp lệ")
                }
            })
        }
    }, [])

    const previewAll = () => {
        const layers = ['one', 'two', 'three', 'four'];
        const iterateSchema = (index, prefix = '') => {
            const currentKey = layers[index];
            const currentSchema = schema[currentKey];
            const currentPart = previewPart(currentSchema);

            if (index === layers.length - 1) {
                if (currentPart.length === 0 && prefix !== '') {
                    return [prefix]; // Return parent's value if no currentPart but prefix exists
                }
                return currentPart.map((part) => `${prefix}${part}`);
            }

            let result = [];

            for (const value of currentPart) {
                const nextResults = iterateSchema(index + 1, `${prefix}${value}.`);
                if (nextResults !== null && nextResults.length > 0) {
                    result = result.concat(nextResults);
                }
            }

            if (result.length === 0 && prefix !== '') {
                result.push(prefix); // Add parent's value if no results but prefix exists
            }

            return result.length > 0 ? result : null; // Return null if result is empty
        };

        const values = iterateSchema(0);

        const firstFiveResults = values ? values.slice(0, 5) : [];
        const lastFiveResults = values ? values.slice(-5) : [];

        const tags = [...firstFiveResults, ...lastFiveResults];


        const dataValid = (tags.length > 0) ? tags.every((item) => {
            const test = item.split('.');
            return ((test.length === 4) && (!test.some(part => part === '')))
        }) : false;

        return {
            data: tags,
            dataValid: dataValid,
        };
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleDone = () => {
        // Handle saving or processing the final schema

        const cleanSchema = (schema) => {
            const cleanedSchema = { ...schema };

            // Iterate over each key in the binSchema object
            for (const key in cleanedSchema) {
                const { prefix, suffix } = cleanedSchema[key];

                // Check if prefix has empty valueType or fieldStart
                if (prefix.valueType === '' || prefix.fieldStart === '') {
                    cleanedSchema[key].prefix = { ...part };
                    cleanedSchema[key].suffix = { ...part };
                }

                // Check if suffix has empty valueType or fieldStart
                if (suffix.valueType === '' || suffix.fieldStart === '') {
                    cleanedSchema[key].suffix = { ...part };
                }

                if ((suffix.valueType === 'string' || suffix.valueType === 'numeric' || suffix.valueType === 'step') && (suffix.fieldEnd === '')){
                    cleanedSchema[key].suffix = { ...part };
                }
            }

            return cleanedSchema;
        };

        setSchema((prevSchema) => {
            return cleanSchema(prevSchema)

        });

        const test = previewAll().dataValid

        if (!test) {
            notify('error', "Dữ liệu mã bin chưa hoàn thiện")
            return;
        }

        api.post('/inventory/bin-schema/set', {schema: schema, id: schemaId}).then((response) => {
            if (response) {
                if (schemaId === 'new'){
                    reloadBinSchema()
                    navigate('/inventory/bin-manager/')
                } else {
                    reloadBinSchema()
                    notify('info', response)
                }
            }
        })
    };

    const handleFieldValueChange = (stepKey, fieldKey, fieldType, value) => {
        setSchema((prevSchema) => {
            const updatedSchema = { ...prevSchema };
            const fieldToUpdate = updatedSchema[stepKey][fieldKey];
            const updatedField = { ...fieldToUpdate };

            if (typeof value === 'string')
                value = value.trim()

            if (fieldType === 'valueType') {
                updatedField.valueType = value;
            } else if (fieldType === 'prefix' || fieldType.startsWith('prefix.')) {
                const prefixField = fieldType.split('.')[1];
                updatedField.prefix = { ...fieldToUpdate.prefix, [prefixField]: value };
            } else if (fieldType === 'suffix' || fieldType.startsWith('suffix.')) {
                const suffixField = fieldType.split('.')[1];
                updatedField.suffix = { ...fieldToUpdate.suffix, [suffixField]: value };
            } else {
                updatedField[fieldType] = value;
            }

            updatedSchema[stepKey][fieldKey] = updatedField;

            return updatedSchema;
        });
    };
    const renderFields = (fields, stepKey) => {
        return (
            <Row gutter={12}>
                {Object.entries(fields).map(([fieldKey, field]) => (
                    <Col span={8} key={fieldKey}>
                        <Card title={fieldKey}>
                            <Form.Item label="Kiểu dữ liệu">
                                <Select
                                    value={field.valueType}
                                    onChange={(value) =>
                                        handleFieldValueChange(stepKey, fieldKey, 'valueType', value)
                                    }
                                >
                                    {valueTypeOptions.map((option) => (
                                        <Option key={option} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Bắt đầu">
                                <Input
                                    disabled={
                                        !field.valueType || (field.valueType === '')
                                    }
                                    value={field.fieldStart}
                                    onChange={(e) =>
                                        handleFieldValueChange(stepKey, fieldKey, 'fieldStart', e.target.value)
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Kết thúc">
                                <Input
                                    disabled={
                                        field.valueType === 'label' || !field.valueType || (field.valueType === '')
                                    }
                                    value={field.fieldEnd}
                                    onChange={(e) =>
                                        handleFieldValueChange(stepKey, fieldKey, 'fieldEnd', e.target.value)
                                    }
                                />
                            </Form.Item>
                        </Card>
                    </Col>
                ))
                }
                <Col span={8}>
                    <Card
                        title="Preview"
                        style={{height: '100%'}}
                    >
                        <div style={{lineHeight: 2.2}}>
                            {generateTags(previewAll().data)}
                        </div>
                    </Card>
                </Col>
            </Row>
        )
    };

    return (
        <Card title="Tạo mã Bin" extra={<Button icon={<RollbackOutlined />} onClick={() => {navigate('/inventory/bin-manager/')}}>Danh mục Bin</Button>}>
            <Steps
                current={currentStep}
                items={
                    Object.keys(binSchema).map((stepKey, index) => ({key: index, title: stepKey}))
                }
            />
            <div style={contentStyle}>
                {renderFields(schema[Object.keys(schema)[currentStep]], Object.keys(schema)[currentStep])}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {currentStep > 0 && (
                    <Button disabled={mustStop} onClick={handlePrevious} icon={<ArrowLeftOutlined />}>Trước</Button>
                )}
                {currentStep < Object.keys(binSchema).length - 1 && (
                    <Button disabled={mustStop} type="primary" onClick={handleNext} icon={<ArrowRightOutlined />}>Tiếp tục</Button>
                )}
                {currentStep === Object.keys(binSchema).length - 1 && (
                    <Button disabled={mustStop} type="primary" onClick={handleDone} icon={<CheckOutlined />}>Hoàn tất</Button>
                )}
            </div>
        </Card>
    );
};
export default SchemaBuilder;
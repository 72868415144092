import './App.css';
import MainContainer from "./Components/Common/MainContainer";
import {BrowserRouter} from "react-router-dom";
import {useEffect} from "react";
import {getJWT} from "./Helpers";
import {userInfoSchema, userInfoStore} from "./Helpers/user-store";


function App() {
    const userInfo = userInfoStore(state => state.info)
    const setUserInfo = userInfoStore(user => user.update)

    useEffect(() => {
        const routineJWTCheck = setInterval(() => {
            if (!getJWT()){
                const isLoggedIn = userInfo.isLoggedIn;
                setUserInfo(userInfoSchema);

                if (isLoggedIn)
                    window.location.href = '/logout/?force=true';
            }
        }, 15000);

        return () =>  {
            clearInterval(routineJWTCheck);
        }
    }, [userInfo])

    return (
        <BrowserRouter>
            <div className="App">
                <MainContainer />
            </div>
        </BrowserRouter>

    );
}

export default App;

import SakuraSpinner from "../../../Helpers/sakura-spinner-helper";
import {useEffect, useState} from "react";
import api from "../../../Helpers/api-helper";
import {getSiteID, setJWT} from "../../../Helpers";
import {notify} from "../../../Helpers/toast-helper";
import {Button, Modal, Select} from "antd";

const PostAuth = () => {

    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)
    const [system, setSystem] = useState('')

    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    useEffect(() => {
        if (!token){
            setLoading(false);
            notify("error", "Không tìm thấy dữ liệu", 5);

            return;
        }

        setModalOpen(true);

    }, []);

    const handleOk = () => {

        if (system === ''){
            notify('error', 'Vui lòng chọn hệ thống muốn truy cập');
            return;
        }

        setModalOpen(false);
        setLoading(true)

        api.post("/post-auth", {token: token, site_id: getSiteID(), system: system}).then(async (resp) => {
            let user = setJWT(resp);
            if (!user){ //Lỗi JWT không decode được
                return false;
            }

            window.location.href = "/";

        }).catch(e => {
            notify("error", e.message, 5);
        }).finally(() => {
            setLoading(false);
        })

    }

    return(
        <div>
            <SakuraSpinner open={loading}/>
            <Modal
                open={modalOpen}
                centered
                title="Chọn hệ thống cần đăng nhập"
                onOk={handleOk}
                footer={[
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        Vào hệ thống
                    </Button>
                ]}
            >

                <Select
                    onChange={setSystem}
                    placeholder={'Chọn hệ thống đăng nhập'}
                    style={{width: '100%', marginTop: '20px', marginBlock: '20px'}}
                    options={[
                        {
                            value: 'TOKYOLIFE',
                            label: 'TOKYOLIFE',
                        },
                        {
                            value: 'FORMAT',
                            label: 'FORMAT',
                        }
                    ]}
                />

            </Modal>
        </div>

    )
}

export default PostAuth;

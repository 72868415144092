import {
    BoxArrowInDown,
    BoxArrowInUp, BoxArrowRight,
    Cart,
    CartCheck,
    ClipboardCheck,
    ColumnsGap,
    Funnel,
    Receipt
} from "react-bootstrap-icons";
import {getJWT} from "./index";


const EC = ['TOKYOLIFE', 'FORMAT'];

const roles = {
    SUPER_ROOT: 65535,
    ROOT: 1024,
    ADMIN: 512,
    USER: 256
}

const buildItem = (label, scope, permission, icon, link, children) => {
    return {
        label,
        scope,
        permission,
        icon,
        link,
        children
    }
}

const MenuItems = [
    buildItem('Đơn hàng', [...EC], null, <Receipt />, '#', [
        buildItem('Nhặt hàng', [...EC], null, <Cart />, '/orders/pick'),
        buildItem('Kiểm đơn', [...EC], null, <ClipboardCheck />, '/orders/check'),
        buildItem('Chia hàng', [...EC], null, <Funnel />, '/orders/sort')
    ]),
    buildItem('Kho', [...EC], [], <ColumnsGap />, '#', [
        buildItem('Nhập hàng', [...EC], null, <BoxArrowInDown />, '/inventory/in'),
        buildItem('Xuất hàng', [...EC], null, <BoxArrowInUp />, '/inventory/out'),
        buildItem('Kiểm kê', [...EC], null, <CartCheck />, '/inventory/check')
    ]),
    buildItem('Quản trị', [...EC], [roles.ADMIN], <ColumnsGap />, '#', [
        buildItem('Nhập kho Excel', [...EC], [roles.ADMIN], <BoxArrowInDown />, '/inventory/in'),
        buildItem('Danh mục bin', [...EC], [roles.ADMIN], <BoxArrowInDown />, '/inventory/bin-manager'),
        buildItem('Logs', [...EC], [roles.ROOT], <BoxArrowInUp />, '/inventory/out'),
    ]),
    {type: 'divider'},
    buildItem('Thoát', null, [], <BoxArrowRight />, '/logout')
]

const processMenuItems = (userInfo, children, initKey) => {
    let keyIndex = 0;
    const userJWT = getJWT();

    if (!userJWT || !userJWT.permission || typeof userJWT.permission !== 'object')
        return false;

    const user = {
        system: userJWT.system,
        permission: userJWT.permission[userJWT.system]
    }

    const Menu = children ? children : MenuItems;

    const updatedMenuItems = Menu.map(menuItem => {
        if ((menuItem.type) || ((menuItem.scope && (menuItem.scope.length > 0)) && (menuItem.scope.includes(userInfo.system)))){

            if ((menuItem?.permission?.length > 0) && !menuItem.permission.every((permission) => user.permission >= permission)) {
                return false;
            }

            delete menuItem.scope;
            delete menuItem.permission;
            keyIndex++;

            if (menuItem.children) {
                menuItem.children = processMenuItems(userInfo, menuItem.children, keyIndex); // Recursive call
            }
            return {...menuItem, key: initKey ? String( initKey + '-' + keyIndex) : 'root-' + keyIndex};
        }
    }).filter(Boolean);

    return updatedMenuItems.filter(Boolean); // Remove any undefined/null values
};

const rootKeys = (processedMenu) => {
    return processedMenu.map(item => item.key)
}

function getLink(menu, key) {
    for (const obj of menu) {
        if (obj.key === key) {
            return obj.link;
        }

        if (obj.children && obj.children.length > 0) {
            const childResult = getLink(obj.children, key);
            if (childResult) {
                return childResult;
            }
        }
    }

    return null;
}

export {processMenuItems, rootKeys, getLink}
import {UserOutlined} from "@ant-design/icons";
import {Button, Dropdown} from "antd";
import {useState} from "react";
import {userInfoStore} from "../../../Helpers/user-store";
import {BoxArrowRight} from "react-bootstrap-icons";
import {getSiteID} from "../../../Helpers";

export const LoginArea = () => {
    const user = userInfoStore(state => state.info);

    const items = [
        {
            key: '1',
            danger: true,
            icon: <BoxArrowRight />,
            label: (
                <a href="/logout">
                    Thoát
                </a>
            ),
        }
    ];

    const [loginLoadingStatus, setLoginLoadingStatus] = useState(false);
    const toLogin = () => {
        setLoginLoadingStatus(true);
        window.location.href = 'https://login.forlife.vn/?site=' + getSiteID();
    }

    if (!user.isLoggedIn){
        return(
            <Button loading={loginLoadingStatus} style={{color: "#961B20", fontWeight: "500"}} onClick={toLogin} icon={<UserOutlined />}>
                Đăng nhập
            </Button>
        )
    } else {
        return(
            <div>
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={['click']}
                    placement="bottomRight"
                >
                <span style={{color: "white", cursor: 'pointer'}}>
                    <UserOutlined /> {user.name}
                </span>
                </Dropdown>
            </div>
        )
    }

}

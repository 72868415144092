import {Drawer, Menu} from "antd";
import {useNavigate} from "react-router-dom";
import {userInfoStore} from "../../../Helpers/user-store";
import {getLink, processMenuItems, rootKeys} from "../../../Helpers/main-menu";
import {useEffect, useState} from "react";

const MenuDrawer = (props) => {
    const navigate = useNavigate();
    const userInfo = userInfoStore(state => state.info)

    const [items, setItems] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const [rootMenuKeys, setRootMenuKeys] = useState([]);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootMenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    useEffect(() => {
        if (userInfo.isLoggedIn) {
            const menu = processMenuItems(userInfo);
            setRootMenuKeys(rootKeys(menu));
            setItems(menu)
        }
    }, [userInfo])


    const onMenuClick = (e) => {
        const key = e.key;
        const link = getLink(items, key);

        if (link) {
            props.setVisible(false);
            navigate(link);
        }
    }

    return(
    <Drawer
        title="Menu"
        placement="left"
        closable={true}
        onClose={props.handleCloseDrawer}
        open={props.drawerVisible}
        width={280}
    >
        <Menu
            onClick={onMenuClick}
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            style={{
                width: '230px',
                fontWeight: '500',
                borderInlineEnd: 'none'
            }}
            items={items}>
        </Menu>

    </Drawer>
    )
}

export default MenuDrawer

import {Image} from "antd";
import {useEffect} from "react";
import {deleteJWT} from "../../../Helpers";
import {userInfoStore, userInfoSchema} from "../../../Helpers/user-store";

const LogoutPage = () => {
    const updateUserInfo = userInfoStore(state => state.update)

    useEffect(() => {
        deleteJWT();
        updateUserInfo(userInfoSchema);
    }, [])

    return(
        <div style={{paddingTop: "50px", textAlign: "center"}}>
            <span className="logout-text">Good bye!</span>
        </div>
    )
}

export default LogoutPage;

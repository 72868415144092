import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts.css';
import App from './App';
import {ConfigProvider} from "antd";
import {Toaster} from "react-hot-toast";
import { App as AntdApp } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //<React.StrictMode>
        <ConfigProvider
            theme={{
                token: {
                    screenLGMin: 1024,
                    screenLG: 1024,
                    borderRadius: '8px',
                    controlHeight: 34,
                    fontFamily: 'Google Sans,Roboto,Arial,sans-serif; !important'
                    /*
                    colorPrimary: '#3da9fc',
                    colorTextHeading: '#094067',
                    colorText: '#5f6c7b',
                    colorError: '#ef4565'
                    //fontWeightStrong: 500
                     */
                },
            }}
        >
            <div><Toaster /></div>
            <AntdApp>
                <App />
            </AntdApp>
        </ConfigProvider>
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

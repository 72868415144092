import axios from 'axios';
import {notify} from "./toast-helper";
import {getJWT} from "./index";
import {modalToast} from "./toast-helper";
axios.interceptors.request.use(function (config) {
    config.baseURL = process.env.REACT_APP_API_URL;
    config.headers.set("Authorization", 'Bearer ' + getJWT()?.jwt);

    return config;
}, function (error) {
    console.log("ERROR", error);
    notify("error", error);
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {


    if (response?.data?.message) {
        const { error, message } = response?.data || {};

        if (error === 1) {
            notify("error", message);
            return null;
        }

        if (error === 99) {
            modalToast( message);
            return null;
        }

        if (error === 0) {
            return message;
        }

        notify("error", "Lỗi dữ liệu trả về từ hệ thống");
    }

    return null;

}, function (error) {

    notify("error", error.message);
    //return Promise.reject(error);
});


const api = axios;
export default api;

import { Checkbox, Input, Modal, Tabs} from "antd";
import {IconBarCode,} from "../../../Helpers/icons-helper";
import {useEffect, useRef, useState} from "react";
import {InboxesFill, TagFill} from "react-bootstrap-icons";
import { testBin } from "../../../Helpers/bin-helper";
import {notify} from "../../../Helpers/toast-helper";

const ModalScanner = (props) => {
    const {open, close} = props;

    const Scanner = (props) => {
        const inputRef =useRef(null);
        const {tab} = props;

        const [suffixDisplay, setSuffixDisplay] = useState(false)
        const checkInputRef = () => {
            return new Promise((resolve) => {
                const checkVariable = () => {
                    if (inputRef.current) {
                        resolve();
                    } else {
                        setTimeout(checkVariable, 100);
                    }
                };
                checkVariable();
            });
        }

        useEffect(() => {
            setSuffixDisplay(tab === "2");

            checkInputRef().then(() => {
                inputRef.current.focus();
            })

        },[inputRef])

        const inputBlur = () => {
            checkInputRef().then(() => {
                inputRef.current.focus();
            })
        }

        const [inputTest, setInputTest] = useState(false)
        const [inputValue, setInputValue] = useState('');
        const onCheckboxChange = (state) => {
            setInputTest(state.target.checked);
        }

        const inputEnter = (e) => {
            if (tab === "2" && inputTest) {
                if (testBin(inputValue)) {
                    notify('success', 'Mã bin ' + inputValue + ' hợp lệ')
                } else {
                    notify('error', 'Mã bin ' + inputValue + ' không hợp lệ')
                }
            }
            setInputValue('')
        }

        return(
            <div style={{marginTop: '16px', marginBottom: '40px'}}>
               <Input size="large" value={inputValue} onChange={(e) => setInputValue(e.target.value)} onPressEnter={inputEnter} prefix={<IconBarCode />} ref={inputRef} onBlur={inputBlur} suffix={suffixDisplay ? <Checkbox onChange={onCheckboxChange}>Test Bin#</Checkbox> : null}/>
            </div>
        )
    }

    const [tab, setTab] = useState(1)
    const tabChange = (tabIndex) => {
        setTab(tabIndex)
    }

    const tabItems = [
        {
            key: '1',
            label: (<span><TagFill /> Mã hàng</span>),
            children: <Scanner tab={tab}/>,
        },
        {
            key: '2',
            label: (<span><InboxesFill /> Mã Bin</span>),
            children: <Scanner tab={tab}/>
        }
    ];

    return(
        <Modal
            title="Quét mã bin/mã hàng"
            centered
            open={open}
            okText="Đóng lại"
            cancelText="Bỏ qua"
            onOk={() => close(false)}
            onCancel={() => close(false)}
        >
            <Tabs defaultActiveKey="1" items={tabItems} onChange={tabChange}/>
        </Modal>
    )
}

export default ModalScanner
import {useEffect} from "react";
import {Content} from "antd/es/layout/layout";
import {getJWT} from "../../../Helpers";
import {userInfoStore, userInfoSchema} from "../../../Helpers/user-store";

export const ProtectedRoute = ({ children }) => {
    const userInfo = userInfoStore(user => user.info)
    const setUserInfo = userInfoStore(user => user.update)

    useEffect(() => {
        const jwt = getJWT();

        if (jwt){
            setUserInfo({
                isLoggedIn: true,
                name: jwt.name,
                uid: jwt.uid,
                permission: jwt.permission,
                system: jwt.system
            })
        } else {
            setUserInfo(userInfoSchema)
        }
    }, []);

    /*
    useEffect(() => {
        const jwt = getJWT();

        if (!jwt) {
            setUserInfo(userInfoSchema);
        }
    }, [children]);
*/
    if (!userInfo.isLoggedIn){
        return (
            <div style={{paddingTop: "50px", textAlign: "center"}}>
                <span>
                    Bạn chưa đăng nhập. Vui lòng đăng nhập trước khi sử dụng.<br />
                </span>
            </div>
        )
    }

    return (
        <Content className="site-layout" style={{ padding: '15px 5px', maxWidth: "1000px", margin: "auto", alignItems: 'center', justifyContent: 'center'}}>
            {children}
        </Content>)
}

import toast from 'react-hot-toast';
import {Button} from "antd";
import {XOctagon} from "react-bootstrap-icons";
export const notify = (type, message, duration = 3) => {
    let toastConfig = {
        duration: duration*1000,
        position: 'top-right',
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            padding: '20px'
        },
    }

    switch (type){
        case "error":
            toast.error(message, toastConfig);
            break;
        case "success":
            toast.success(message, toastConfig);
            break;
        default:
            toast.success(message, toastConfig);
    }
}

export const modalToast = ( message) => {
    toast((t) => {
            return (
                <div>
                    <div>
                        {message}
                    </div>
                    <div style={{marginTop: '15px', textAlign: 'end'}}>
                        <Button danger onClick={() => toast.dismiss(t.id)}>
                            OK
                        </Button>
                    </div>
                </div>
            )
        },
        {
            duration: Infinity,
            icon: <XOctagon style={{fontSize: '2rem', alignSelf: 'start', marginRight: '15px'}}/>,
            style: {
                border: '1px solid #713200',
                padding: '24px',
                color: 'red',
            },
            iconTheme: {
                primary: '#713200',
                secondary: '#FFFAEE',
            }
        }
    )
}
import jwtDecode from "jwt-decode";

export function setJWT(token){
    let jwt;

    try {
        jwt = jwtDecode(token);
    } catch (e) {
        return false;
    }


    const token_data = {
        ...jwt,
        jwt     : token
    };

    window.localStorage.setItem("jwt-token", JSON.stringify(token_data));
    return token_data;
}
export function getJWT() {
    const jwtToken = window.localStorage.getItem("jwt-token");
    if (!jwtToken) {
        return false;
    }

    try {
        const jwtPayload = JSON.parse(jwtToken);
        const { uid, name, exp } = jwtPayload;
        const currentTime = Date.now() / 1000;

        if (isNaN(jwtPayload.exp) || jwtPayload.exp < currentTime) {
            window.localStorage.removeItem("jwt-token");
            return false;
        }

        if (uid && name && exp) {
            return jwtPayload;
        }
    } catch (error) {
        console.error("Error parsing JWT token:", error);
    }

    return false;
}
export function deleteJWT(){
    window.localStorage.removeItem("jwt-token");
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function hasProperty(obj, prop) {
    const props = prop.split(".");
    let current = obj;

    for (const p of props) {
        if (!current || !current.hasOwnProperty(p)) {
            return false;
        }
        current = current[p];
    }

    return true;
}

export function isObjectOrArray(input) {
    return Array.isArray(input) || (typeof input === 'object' && input !== null);
}
export function findObjectById(data, id) {
    for (let obj of data) {
        if (obj._id === id) {
            return obj; // Found the object with the matching ID
        } else if (obj.children && obj.children.length > 0) {
            const foundObject = findObjectById(obj.children, id);
            if (foundObject) {
                return foundObject; // Found the object in one of the children
            }
        }
    }
    return null; // Object with the given ID not found
}

//Used for Bookmark Manager
export function extractBookmark(html) {
    //const regex = /<a\s+id="([^"]{1,})">([^<]+)<\/a>/g;
    const regex = /<a\s+id="([^"]{1,})">/g;

    const bookmark = [];
    let match;

    while ((match = regex.exec(html))) {
        bookmark.push(match[1]);
    }

    return bookmark;
}

export function deleteObjectsNotInNew(old, newA) {
    const newBookmarkIds = newA.map(item => item);
    return old.filter(item => newBookmarkIds.includes(item.bookmarkId));
}

export function summarizePhrase(phrase, maxLength = 24) {
    // Remove non-alphanumeric characters and convert to lowercase
    const alphanumeric = phrase.replace(/[^a-z0-9\s]/gi, "").toLowerCase();

    // Remove all spaces
    const withoutSpaces = alphanumeric.replace(/\s/g, "");

    let summarized = "";
    let length = 0;

    // Iterate over each character in the withoutSpaces string
    for (const char of withoutSpaces) {
        // Check if adding the character exceeds the length limit
        if (length + 1 > maxLength) {
            break;
        }

        summarized += char;
        length++;
    }

    return summarized;
}

export const waitFor = (obj, interval = 100) => {
    return new Promise((resolve) => {
        const checkVariable = () => {
            if (obj) {
                resolve();
            } else {
                setTimeout(checkVariable, interval);
            }
        };
        checkVariable();
    });
}

export const filterTreeNode = (inputValue, treeNode) => {
    const label = treeNode.title;
    const regex = new RegExp(inputValue, 'i'); // 'i' flag for case-insensitive matching
    return regex.test(label);
};
export const hasNestedProperty = (obj, propertyPath) => {
    const properties = propertyPath.split('.');
    let currentObj = obj;

    for (let property of properties) {
        if (!currentObj || !currentObj.hasOwnProperty(property)) {
            return false;
        }
        currentObj = currentObj[property];
    }

    return true;
};

export const getRandomItems = (array, count) => {
    const shuffled = array.slice(); // Create a copy of the original array
    let currentIndex = array.length;
    const result = [];

    while (count && currentIndex) {
        const randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // Swap the current element with a random element
        [shuffled[currentIndex], shuffled[randomIndex]] = [
            shuffled[randomIndex],
            shuffled[currentIndex],
        ];

        // Add the randomly selected element to the result
        result.push(shuffled[currentIndex]);
        count--;
    }

    return result;
};

export function getSiteID(){
    return (process.env.REACT_APP_BUILD === 'PROD') ? process.env.REACT_APP_SITE_ID : process.env.REACT_APP_SITE_LOCAL_ID
}
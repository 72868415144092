import api from "./api-helper";
let allowedBinSchema = [];

const reloadBinSchema = () => {
    api.post('/inventory/bin-schema/get', {minimal: true}).then((response) => {
        if (response && typeof response === 'object') {
            allowedBinSchema = response.map(item => item.schema)
            console.log('Bin schema reloaded');
            //console.log(JSON.stringify(response.map(item => item.schema)))
        }
    })
}
const testBin = (bin) => {
    const binParts = bin.split(".");

    const partsLabel = ['one', 'two', 'three', 'four'];

    if ((binParts.length < 4) || (binParts.some(item => item === '')))
        return false;

    const splitAlphaNumeric = (input) => {
        const regex = /^([A-Za-z]*)(\d*)$/; // Regular expression to match alphabetic and numeric parts
        const matches = input.match(regex); // Extract matches using regex

        if (matches) {
            const [, alphaPart, numericPart] = matches; // Destructure matches array
            return [alphaPart ? alphaPart : numericPart, !alphaPart ? '' : numericPart];
        }

        return ['', ''];
    };

    const testSchema = binParts.map((item, index) => {
        const [prefix, suffix] = splitAlphaNumeric(item);
        return { [partsLabel[index]]: { prefix, suffix } };
    }).reduce((result, obj) => {
        const [key, value] = Object.entries(obj)[0];
        result[key] = value;
        return result;
    }, {});

    //console.log(testSchema)
    function _isCharacterInRange(start, end, input) {
        if (input.length > 1)
            return false;

        const startCode = start.charCodeAt(0);
        const endCode = end.charCodeAt(0);
        const inputCode = input.charCodeAt(0);

        return inputCode >= startCode && inputCode <= endCode;
    }

    const _testArrayEmpty = (input) => {
        return ((typeof input.valueType === 'object') && (input.valueType.length === 0)) || (input === '')
    }
    const _checkValue = (allowed, testing) => {
        const debug = false;

        if (allowed.valueType === 'label') {
            const result = allowed.fieldStart === testing
            if (!result && debug)
                console.log("Diff [label]", allowed.fieldStart, testing);
            return result;
        }

        if (allowed.valueType === 'string') {
            const result = _isCharacterInRange(allowed.fieldStart, allowed.fieldEnd, testing)
            if (!result && debug)
                console.log("Diff [string]", allowed.fieldStart, testing);
            return result;
        }

        if (allowed.valueType === 'numeric') {
            const testLength = allowed.fieldStart.length;
            const start = parseInt(allowed.fieldStart);
            const end = parseInt(allowed.fieldEnd);

            for (let i = start; i <= end; i++) {
                if (i.toString().padStart(testLength, '0') === testing) {
                    return true;
                }
            }

            if (debug)
                console.log("Diff [numeric]", allowed.fieldStart ,allowed.fieldEnd, testing);

            return false;
        }

        if (allowed.valueType === 'step') {
            const result =  parseInt(testing) >= (parseInt(allowed.fieldStart)+1)  && parseInt(testing) <= (parseInt(allowed.fieldEnd)+9)
            if (!result && debug)
                console.log("Diff [step]", allowed.fieldStart ,allowed.fieldEnd, testing);
            return result
        }
    }

    let finalBoard = []
    for (const key of Object.keys(testSchema)) {
        const checkPart = testSchema[key];

        const result = allowedBinSchema.map(binSchema => {
            const allowedPart = binSchema[key];
            switch (allowedPart.prefix.valueType) {
                case 'label':
                    return _checkValue(allowedPart.prefix, checkPart.prefix + checkPart.suffix)
                default:
                    return ((allowedPart.prefix.valueType !== '') ? _checkValue(allowedPart.prefix, checkPart.prefix) : false) && ((_testArrayEmpty(allowedPart.suffix.valueType)) ? _checkValue(allowedPart.suffix, checkPart.prefix) : true)
            }
        });
        finalBoard.push(result);
    }

    return finalBoard[0].map((_, colIndex) => finalBoard.map(row => row[colIndex])).some(column => column.every(value => value === true));
}

export {testBin, reloadBinSchema}